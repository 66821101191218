<template>
  <div class="topology-header-component">
    <span class="left-part">
      <span class="go-back" @click="goBackHandler">
        <img
          class="go-back-icon"
          src="@/assets/images/go_back_icon.png"
          alt=""
        />
        <span class="go-back-span">返回</span>
      </span>
      <el-divider direction="vertical"></el-divider>
      <span class="name-span" v-if="!editFlag">
        {{ name }}
        <img
          src="@/assets/images/topology/name_edit_icon.png"
          @click="editHandler"
          alt=""
        />
      </span>
      <span v-else>
        <el-input
          v-model="name"
          size="mini"
          ref="editInput"
          maxlength="20"
          @input="nameChange"
          @keyup.enter.native="editFlag = false"
          @blur="editFlag = false"
        ></el-input>
      </span>
    </span>
    <span class="center-part">
      <div
        :class="['center-tools', item.disabled && 'center-tools-disabled']"
        v-for="(item, index) in centerTools"
        :key="index"
        @click="toolClickHandler(item)"
      >
        <img class="tool-icon" :src="item.icon" alt="" />
        <span class="tool-name">
          {{ item.label }}
        </span>
      </div>
    </span>
    <span class="right-part">
      <!-- <span class="scale-container">
        <img
          class="ope-button"
          src="@/assets/images/topology/zoomin_icon.png"
          alt=""
        />
        <span class="scale-span">{{ `${scale * 100}%` }}</span>
        <img
          class="ope-button"
          src="@/assets/images/topology/zoomout_icon.png"
          alt=""
        />
      </span> -->
      <div
        :class="['center-tools', item.disabled && 'center-tools-disabled']"
        v-for="(item, index) in rightTools"
        :key="index"
        @click="toolClickHandler(item)"
      >
        <img class="tool-icon" :src="item.icon" alt="" />
        <span class="tool-name">
          {{ item.label }}
        </span>
      </div>
      <div class="release-button" @click="releaseHandler">
        <img src="@/assets/images/topology/release_button_icon.png" alt="" />
        <span class="button-name"> 发布 </span>
      </div>
    </span>
  </div>
</template>

<script>
export default {
  name: "topologyHeaderComponent",
  props: {
    currentId: {
      type: String,
      default() {
        return null;
      },
    },
    undoList: {
      type: Array,
      default() {
        return [];
      },
    },
    releaseName: {
      type: String,
      default() {
        return "";
      },
    },
  },
  data() {
    return {
      name: "新项目",
      scale: 1.2,
      editFlag: false,
      centerTools: [
        {
          label: "撤销",
          eventName: "revoke",
          disabled: true,
          icon: require("@/assets/images/topology/revoke_icon.png"),
        },
        {
          label: "子主题",
          eventName: "subitem",
          disabled: true,
          icon: require("@/assets/images/topology/subitem_icon.png"),
        },
      ],
      rightTools: [
        {
          label: "预览",
          eventName: "view",
          disabled: false,
          icon: require("@/assets/images/topology/view_icon.png"),
        },
        {
          label: "导出",
          eventName: "export",
          disabled: false,
          icon: require("@/assets/images/topology/export_icon.png"),
        },
      ],
    };
  },
  watch: {
    undoList: {
      handler(undoList) {
        this.centerTools.forEach((item) => {
          if (item.label === "撤销") {
            item.disabled = !(undoList && undoList.length);
          }
        });
      },
      immediate: true,
      deep: true,
    },
    currentId(node) {
      this.centerTools.forEach((item) => {
        if (item.label === "子主题") {
          item.disabled = node === null;
        }
      });
    },
    releaseName: {
      handler(name) {
        this.name = name || "新项目";
      },
    },
  },
  methods: {
    nameChange() {
      this.$emit("update", this.name);
    },
    releaseHandler() {
      this.$emit("headerEvent", "release");
    },
    toolClickHandler({ eventName }) {
      this.$emit("headerEvent", eventName);
    },
    goBackHandler() {
      this.$emit("headerEvent", "goBack");
    },
    editHandler() {
      this.editFlag = true;
      this.$nextTick(() => {
        this.$refs.editInput.focus();
      });
    },
  },
};
</script>

<style lang="less" scoped>
.topology-header-component {
  height: 70px;
  line-height: 70px;
  background: #f1f6fd;
  padding: 0 40px;
  border-bottom: 1px solid #e3e8ee;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .left-part {
    display: flex;
    align-items: center;
    .go-back {
      cursor: pointer;
      .go-back-icon {
        margin-right: 5px;
        position: relative;
        top: 1px;
      }
      .go-back-span {
        font-size: 18px;
        font-weight: bold;
        color: #2a61ff;
        position: relative;
      }
    }
    .name-span {
      font-size: 18px;
      font-weight: bold;
      color: #152c5b;
      line-height: 21px;
      display: flex;
      align-items: center;
      img {
        margin-left: 2px;
        cursor: pointer;
      }
    }
  }
  .center-part {
    display: flex;
  }
  .right-part {
    display: flex;
    align-items: center;
    .scale-container {
      display: flex;
      align-items: center;
      margin-right: 25px;
      .scale-span {
        margin: 0 5px;
      }
      .ope-button {
        cursor: pointer;
      }
    }
    .release-button {
      padding: 0 15px;
      margin-left: 20px;
      width: 90px;
      height: 36px;
      background: #2a61ff;
      border-radius: 8px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .button-name {
        font-size: 14px;
        font-weight: 600;
        color: #ffffff;
        line-height: 16px;
      }
    }
  }
  .center-tools {
    height: 50px;
    width: 54px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 6px;
    .tool-icon {
      height: 18px;
      width: 18px;
    }
    .tool-name {
      display: inline-block;
      font-size: 12px;
      font-weight: 500;
      color: #152c5b;
      line-height: 12px;
      margin-top: 6px;
    }
    &:hover {
      background: #e8effb;
      filter: none;
    }
  }
  .center-tools-disabled {
    filter: contrast(0.2);
    cursor: not-allowed;
  }
  .center-tools + .center-tools {
    margin-left: 10px;
  }
  ::v-deep .el-divider--vertical {
    height: 10px;
    margin: 0 10px;
  }
}
</style>
