var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "topology-right-drawer" }, [
    _c("div", { staticClass: "device-container" }, [
      _vm._m(0),
      _c("div", { staticClass: "selector-container" }, [
        _c("div", { staticClass: "selector-label" }, [_vm._v("产品")]),
        _c(
          "div",
          [
            _c(
              "el-select",
              {
                staticClass: "single-selector",
                attrs: { filterable: "", placeholder: "请选择产品" },
                on: { change: _vm.productChange },
                model: {
                  value: _vm.product,
                  callback: function ($$v) {
                    _vm.product = $$v
                  },
                  expression: "product",
                },
              },
              _vm._l(_vm.productOptions, function (item) {
                return _c("el-option", {
                  key: item.value,
                  attrs: { label: item.label, value: item.value },
                })
              }),
              1
            ),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "selector-container" }, [
        _c("div", { staticClass: "selector-label" }, [_vm._v("设备")]),
        _c(
          "div",
          [
            _c(
              "el-select",
              {
                staticClass: "single-selector",
                attrs: {
                  filterable: "",
                  "remote-method": _vm.remoteMethod,
                  remote: "",
                  placeholder: "请选择设备",
                },
                on: { change: _vm.deviceChange },
                model: {
                  value: _vm.device,
                  callback: function ($$v) {
                    _vm.device = $$v
                  },
                  expression: "device",
                },
              },
              _vm._l(_vm.deviceOptions, function (item) {
                return _c(
                  "el-option",
                  {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  },
                  [
                    _c("span", { staticStyle: { float: "left" } }, [
                      _vm._v(_vm._s(item.label)),
                    ]),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          float: "right",
                          color: "#8492a6",
                          "font-size": "13px",
                        },
                      },
                      [_vm._v(_vm._s(item.value))]
                    ),
                  ]
                )
              }),
              1
            ),
          ],
          1
        ),
      ]),
    ]),
    _c("div", { staticClass: "style-container" }, [
      _vm._m(1),
      _c("div", { staticClass: "text-container" }, [
        _c("div", { staticClass: "label-line" }, [_vm._v("文本")]),
        _c(
          "div",
          { staticClass: "font-line" },
          [
            _c("el-input-number", {
              staticStyle: { width: "140px" },
              attrs: { "controls-position": "right", min: 12, max: 30 },
              on: { change: _vm.fontSizeChange },
              model: {
                value: _vm.style.fontSize,
                callback: function ($$v) {
                  _vm.$set(_vm.style, "fontSize", $$v)
                },
                expression: "style.fontSize",
              },
            }),
            _c("el-color-picker", {
              staticClass: "color-selector",
              on: { change: _vm.fontColorChange },
              model: {
                value: _vm.style.color,
                callback: function ($$v) {
                  _vm.$set(_vm.style, "color", $$v)
                },
                expression: "style.color",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "font-style-line" },
          _vm._l(_vm.fontStyleItems, function (item, index) {
            return _c(
              "span",
              {
                key: index,
                class: [
                  "single-item",
                  item.active && "single-item-active",
                  index > 0 && "single-border-span",
                ],
                on: {
                  click: function ($event) {
                    return _vm.fontStyleChange(item)
                  },
                },
              },
              [_c("img", { attrs: { src: item.icon, alt: "" } })]
            )
          }),
          0
        ),
      ]),
      _c("div", { staticClass: "tag-container" }, [
        _c("div", { staticClass: "label-line" }, [_vm._v("标签")]),
        _c(
          "div",
          { staticClass: "tag-line" },
          _vm._l(_vm.tagItems, function (item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "tag-detail",
                style: {
                  background: item.color,
                },
                on: {
                  click: function ($event) {
                    return _vm.tagChange(item, index)
                  },
                },
              },
              [
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: item.active,
                      expression: "item.active",
                    },
                  ],
                  attrs: {
                    src: require("@/assets/images/topology/item_active_icon.png"),
                    alt: "",
                  },
                }),
              ]
            )
          }),
          0
        ),
      ]),
      _c(
        "div",
        { staticClass: "remark-container" },
        [
          _c("div", { staticClass: "label-line" }, [_vm._v("备注")]),
          _c("el-input", {
            attrs: {
              type: "textarea",
              rows: 4,
              autosize: false,
              placeholder: "请输入内容",
            },
            on: { input: _vm.remarkChange },
            model: {
              value: _vm.nodeRemark,
              callback: function ($$v) {
                _vm.nodeRemark = $$v
              },
              expression: "nodeRemark",
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title-line" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/images/topology/device_title_icon.png"),
          alt: "",
        },
      }),
      _c("span", { staticClass: "title-label" }, [_vm._v(" 设备绑定 ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "title-line" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/images/topology/style_title_icon.png"),
          alt: "",
        },
      }),
      _c("span", { staticClass: "title-label" }, [_vm._v(" 样式编辑 ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }