<template>
  <div class="topology-detail-component">
    <TopoLogyHeader
      @headerEvent="headerEventHandler"
      :releaseName="releaseName"
      @update="releaseNameUpdate"
      :undoList="undoList"
      :currentId="currentId"
    />
    <div
      class="graph-container"
      v-loading="graphLoading"
      id="graphContainer"
    ></div>
    <RightDetailDrawer
      class="right-detail-drawer"
      @propsChange="propsChangeHandler"
      :currentDatas="currentDatas"
      :style="{
        right: drawerDatas.showDrawer ? '0' : '-280px',
      }"
    />
    <el-dialog
      :close-on-click-modal="false"
      width="400px"
      :visible.sync="releaseDialog.show"
      :before-close="releaseDialogClose"
    >
      <template slot="title">
        <title-icon />{{ releaseDialog.title }}
      </template>
      <el-form
        ref="releaseForm"
        :model="releaseForm"
        :rules="rules"
        label-position="top"
      >
        <el-form-item label="项目名称" prop="releaseName">
          <el-input
            style="width: 100%"
            maxlength="20"
            v-model="releaseForm.releaseName"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" style="text-align: center">
        <el-button type="primary" @click="releaseSaveHandler">发 布</el-button>
        <el-button @click="releaseDialogClose">取 消</el-button>
      </div>
    </el-dialog>
    <TopoDownloadDialog
      :show="downloadDialogShow"
      @close="downloadDialogShow = false"
    />
  </div>
</template>

<script>
import TopoLogyHeader from "./components/header.vue";
import RightDetailDrawer from "./components/rightDetailDrawer.vue";
import TopoDownloadDialog from "./components/topoDownloadDialog.vue";
import {
  createTopo,
  editTopo,
  findTopoDetail,
  publishTopo,
} from "@/api/ruge/topo/topo.js";
import { debounce } from "lodash";
export default {
  name: "topologyDetailComponent",
  components: {
    TopoLogyHeader,
    RightDetailDrawer,
    TopoDownloadDialog,
  },
  data() {
    return {
      releaseName: "新项目",
      undoList: [],
      downloadDialogShow: false,
      topoStatus: "draft",
      graphLoading: false,
      jsMindIns: null,
      currentId: null,
      currentDatas: {
        parent: {},
      },
      drawerDatas: {
        showDrawer: false,
      },
      releaseDialog: {
        show: false,
        title: "发布",
        graphName: "",
      },
      releaseForm: {
        releaseName: "新项目",
      },
      rules: {
        releaseName: [
          {
            required: true,
            trigger: ["blur"],
            message: this.$t("validate.required"),
          },
        ],
      },
      intervalIns: null,
      intervalStep: 30 * 1000,
      topoId: null,
      topicRecordMap: {},
    };
  },
  mounted() {
    this.initGraph();
    this.bindEvent();
    this.displayDatas();
    this.startInterval();
  },
  beforeDestroy() {
    this.intervalIns && window.clearInterval(this.intervalIns);
  },
  methods: {
    initGraph() {
      const options = {
        container: "graphContainer",
        theme: "default",
        mode: "side",
        editable: true,
        view: {
          draggable: true,
          hmargin: 300,
          vmargin: 300,
        },
        layout: {
          hspace: 30, // 节点之间的水平间距
          vspace: 10, // 节点之间的垂直间距
          pspace: 13, // 节点与连接线之间的水平间距（用于容纳节点收缩/展开控制器）
          cousin_space: 0, // 相邻节点的子节点之间的额外的垂直间距
        },
        shortcut: {
          enable: true, // 是否启用快捷键
          handles: {
            addChildren: () => {
              this.addNodeHandler();
              // do something...
            },
            addbrothers: () => {
              this.addNodeHandler(true);
              // do something...
            },
          },
          mapping: {
            // 快捷键映射
            //  addchild   : [45, 4096+13], 	// <Insert>, <Ctrl> + <Enter>
            addChildren: 9, // <Tab>
            addbrothers: 13, // <Enter>
            editnode: 113, // <F2>
            delnode: 46, // <Delete>
            toggle: 32, // <Space>
            left: 37, // <Left>
            up: 38, // <Up>
            right: 39, // <Right>
            down: 40, // <Down>
            dosomething: 112, // <F1>
          },
        },
      };
      this.jsMindIns = new jsMind(options);
      this.jsMindIns.show();
      this.jsMindIns.add_event_listener(this.eventCallback);
    },
    eventCallback(type, data) {
      // 看看修改时控制台里有什么数据，选择合适的type完成你的需求。
      console.log("data", data);
      const { evt, node } = data || {};
      if (evt === "select_node") {
        const topic = this.jsMindIns.get_node(node).topic;
        this.topicRecordMap[node] = topic;
        console.log("this.topicRecordMap", this.topicRecordMap);
      }
      if (evt === "update_node") {
        console.log("aaa-update");
        this.undoList.push({
          type: "topic",
          nodeId: node,
          previousValue: this.topicRecordMap[node],
        });
      }
    },
    bindEvent() {
      $("#graphContainer").on("click", ".js_node", (event) => {
        // event.stopPropagation();
        const currentId = event.target.dataset.id;
        if (!currentId) return;
        this.currentId = currentId;
        this.jsMindIns.select_node(event.target.dataset.id);
        this.drawerDatas.showDrawer = true;
      });
      $("#graphContainer").on("click", (event) => {
        setTimeout(() => {
          const currentNode = this.jsMindIns.get_selected_node();
          if (currentNode) {
            console.log("currentNode: ", currentNode.data);
            console.log("currentNode: ", currentNode.parent);
            this.currentDatas = currentNode.data;
            if (currentNode.parent) {
              this.currentDatas.parent = currentNode.parent.data;
            }
            this.currentId = currentNode.id;
            this.drawerDatas.showDrawer = true;
            // setTimeout(() => {
            this.jsMindIns.set_node_color(
              this.currentId,
              currentNode.data["background-color"]
            );
            // }, 50);
          } else {
            this.drawerDatas.showDrawer = false;
            this.currentId = null;
          }
        }, 100);
      });
      document.onkeydown = (e) => {
        const { keyCode } = e;
        e.stopPropagation();
        if (keyCode === 46) {
          this.nodeDeleteHandler();
        }
        if (e.ctrlKey && e.keyCode == 90) {
          this.undoListHandler();
        }
      };
    },
    nodeDeleteHandler() {
      const deleteNode = this.jsMindIns.get_node(this.currentId);
      console.log("deleteNode", deleteNode);
      this.undoList.push({
        type: "delete",
        nodeDatas: {
          ...deleteNode,
          ...{
            nodeId: deleteNode.id,
            parentId: deleteNode.parent.id,
            beforeId: deleteNode.parent.children[deleteNode.index]
              ? deleteNode.parent.children[deleteNode.index].id
              : "",
            topic: deleteNode.topic,
            data: deleteNode.data.data,
            children: this.getDeleteChildren(deleteNode),
          },
        },
      });
      console.log("this.undoList", this.undoList);
      this.jsMindIns.remove_node(this.currentId);
      // jsmind删除了以后会默认选中删除节点的父级，所以清除一下！
      this.jsMindIns.select_clear();
    },
    // 获取删除节点的children，恢复时用
    getDeleteChildren(datas) {
      let childrenList = [];
      function childrenHandler(childDatas, parrentId) {
        childDatas.forEach((item) => {
          childrenList.push({
            nodeId: item.id,
            parentId: parrentId,
            topic: item.topic,
            data: item.data,
          });
          if (item.children && item.children.length) {
            childrenHandler(item.children, item.id);
          }
        });
      }
      childrenHandler(datas.children, datas.id);
      return childrenList;
    },
    childrenDisplay(children) {
      console.log("children", children);
      children.forEach((item) => {
        this.jsMindIns.add_node(item.parentId, item.nodeId, item.topic, {
          data: item.data.data,
        });
        this.updateSingleNodeStyles(item);
      });
    },
    updateSingleNodeStyles(item) {
      console.log("xxx-item", item);
      item.data["font-size"] &&
        this.propsChangeHandler(
          {
            key: "fontSize",
            value: item.data["font-size"],
          },
          item.nodeId,
          true
        );
      item.data["foreground-color"] &&
        this.propsChangeHandler(
          {
            key: "fontColor",
            value: item.data["foreground-color"],
          },
          item.nodeId,
          true
        );
      item.data["font-weight"] &&
        this.propsChangeHandler(
          {
            key: "fontWeight",
            value: item.data["font-weight"],
          },
          item.nodeId,
          true
        );
      item.data["font-style"] &&
        this.propsChangeHandler(
          {
            key: "fontItalic",
            value: item.data["font-style"],
          },
          item.nodeId,
          true
        );
      item.data["background-color"] &&
        this.propsChangeHandler(
          {
            key: "backgroundColor",
            value: item.data["background-color"],
          },
          item.nodeId,
          true
        );
    },
    getDetailHandler(topoId) {
      return findTopoDetail(topoId).then((res) => {
        return res;
      });
    },
    async displayDatas() {
      this.graphLoading = true;
      // 如果跳转有id则赋值给topoId
      const { topoId } = this.$route.query;
      if (topoId) {
        try {
          this.topoId = topoId;
          const detail = await this.getDetailHandler(topoId);
          const { draftTopoJson, topoName, topoStatus } = detail;
          console.log("topoJson", draftTopoJson);
          this.topoStatus = topoStatus;
          this.releaseName = topoName;
          this.jsMindIns.show(JSON.parse(draftTopoJson));
        } catch (error) {
          this.$message.warning("初始化图形失败！");
        } finally {
          this.graphLoading = false;
        }
      } else {
        this.initEmptyScene();
      }
    },
    initEmptyScene() {
      const emptyData = {
        meta: {
          name: "jsMind remote",
          author: "hizzgdev@163.com",
          version: "0.2",
        },
        format: "node_tree",
        data: {
          id: "root",
          topic: "未命名",
          expanded: true,
          ["font-size"]: 20,
          ["foreground-color"]: "#000000",
          ["background-color"]: "#ffffff",
          ["font-weight"]: "normal",
          ["font-style"]: "normal",
          data: { name: "未命名", productKey: "", deviceName: "", remark: "" },
        },
      };
      this.jsMindIns.show(emptyData);
      this.graphLoading = false;
    },
    addNodeHandler(brother) {
      const currentNode = this.jsMindIns.get_node(this.currentId);
      if (brother && !currentNode.parent) return;
      const nodeid = jsMind.util.uuid.newid();
      this.jsMindIns.add_node(
        brother ? currentNode.parent.id : this.currentId,
        nodeid,
        "未命名",
        {
          ["font-size"]: 16,
          ["foreground-color"]: "#000000",
          ["background-color"]: "#ffffff",
          ["font-weight"]: "normal",
          ["font-style"]: "normal",
          data: {
            productKey: brother ? currentNode.data.data.productKey : "",
            deviceName: "",
            remark: "",
          },
        }
      );
      this.undoList.push({
        type: "add",
        nodeId: nodeid,
      });
    },
    undoListHandler() {
      if (!this.undoList.length) return;
      const currentUndo = this.undoList.pop();
      const { type, nodeId, nodeDatas, previousValue } = currentUndo;
      const currentNode = this.jsMindIns.get_node(nodeId);
      switch (type) {
        case "add":
          this.jsMindIns.remove_node(nodeId);
          break;
        case "delete":
          if (nodeDatas.beforeId) {
            this.jsMindIns.insert_node_before(
              nodeDatas.beforeId,
              nodeDatas.nodeId,
              nodeDatas.topic,
              {
                data: nodeDatas.data,
              }
            );
          } else {
            this.jsMindIns.add_node(
              nodeDatas.parentId,
              nodeDatas.nodeId,
              nodeDatas.topic,
              {
                data: nodeDatas.data,
              }
            );
          }
          this.updateSingleNodeStyles(nodeDatas);
          if (nodeDatas.children && nodeDatas.children.length) {
            this.childrenDisplay(nodeDatas.children);
          }
          break;
        case "fontColor":
          this.jsMindIns.set_node_color(nodeId, null, previousValue);
          break;
        case "fontSize":
          this.jsMindIns.set_node_font_style(nodeId, previousValue);
          break;
        case "fontWeight":
          this.jsMindIns.set_node_font_style(nodeId, null, previousValue);
          break;
        case "fontItalic":
          this.jsMindIns.set_node_font_style(nodeId, null, null, previousValue);
          break;
        case "backgroundColor":
          this.jsMindIns.set_node_color(nodeId, previousValue);
          break;
        case "product":
          currentNode.data.data.productKey = previousValue;
          break;
        case "device":
          currentNode.data.data.deviceName = previousValue;
          break;
        case "remark":
          currentNode.data.data.remark = previousValue;
          break;
        case "topic":
          this.jsMindIns.update_node(nodeId, previousValue);
          // update_node触发了bindEvent,又重新push了一条更新的，所以要pop一条记录
          this.undoReset();
          break;
      }
    },
    undoReset() {
      setTimeout(() => {
        this.undoList.pop();
      }, 50);
    },
    propsChangeHandler({ key, value }, handlerId = this.currentId, notRecord) {
      console.log("aaa-key", key);
      console.log("aaa-value", value);
      const currentNode = this.jsMindIns.get_selected_node();
      switch (key) {
        case "product":
          if (!notRecord) {
            this.undoList.push({
              type: "product",
              nodeId: handlerId,
              previousValue: currentNode.data.data.productKey,
            });
          }
          currentNode.data.data.productKey = value;
          break;
        case "device":
          this.undoList.push({
            type: "device",
            nodeId: handlerId,
            previousValue: currentNode.data.data.deviceName,
          });
          currentNode.data.data.deviceName = value[0];
          currentNode.data.data.deviceDesc = `${value[1]}(${value[0]})`;
          // 如果没有手动改名字，则覆盖topic
          // if (currentNode.topic === "未命名") {
          value[1] &&
            this.jsMindIns.update_node(handlerId, `${value[1]}(${value[0]})`);
          // }
          break;
        case "fontColor":
          if (!notRecord) {
            this.undoList.push({
              type: "fontColor",
              nodeId: handlerId,
              previousValue:
                this.jsMindIns.get_selected_node().data["foreground-color"],
            });
          }
          this.jsMindIns.set_node_color(handlerId, null, value);
          break;
        case "fontSize":
          if (!notRecord) {
            this.undoList.push({
              type: "fontSize",
              nodeId: handlerId,
              previousValue:
                this.jsMindIns.get_selected_node().data["font-size"],
            });
          }
          this.jsMindIns.set_node_font_style(handlerId, value);
          break;
        case "fontWeight":
          if (!notRecord) {
            this.undoList.push({
              type: "fontWeight",
              nodeId: handlerId,
              previousValue:
                this.jsMindIns.get_selected_node().data["font-weight"],
            });
          }
          this.jsMindIns.set_node_font_style(handlerId, null, value);
          break;
        case "fontItalic":
          if (!notRecord) {
            this.undoList.push({
              type: "fontItalic",
              nodeId: handlerId,
              previousValue:
                this.jsMindIns.get_selected_node().data["font-style"],
            });
          }
          this.jsMindIns.set_node_font_style(handlerId, null, null, value);
          break;
        case "backgroundColor":
          if (!notRecord) {
            this.undoList.push({
              type: "backgroundColor",
              nodeId: handlerId,
              previousValue:
                this.jsMindIns.get_selected_node().data["background-color"],
            });
          }
          this.jsMindIns.set_node_color(handlerId, value);
          break;
        case "remark":
          this.setValueDebounce(currentNode, value, notRecord, handlerId, this);
          break;
      }
    },
    setValueDebounce: debounce(
      (currentNode, value, notRecord, handlerId, that) => {
        if (!notRecord) {
          const previousValue =
            that.jsMindIns.get_node(handlerId).data.data["remark"];
          that.undoList.push({
            type: "remark",
            nodeId: handlerId,
            previousValue,
          });
          console.log("that.undoList", that.undoList);
        }
        currentNode.data.data["remark"] = value;
      },
      500
    ),
    headerEventHandler(eventName) {
      switch (eventName) {
        case "view":
          this.graftSaveHandler(() => {
            const url = `${location.origin}${location.pathname}#/iot/topology/preview?layout=hide&topoId=${this.topoId}`;
            window.open(url, "_blank");
          });
          break;
        case "export":
          this.downloadDialogShow = true;
          break;
        case "revoke":
          this.undoListHandler();
          break;
        case "subitem":
          this.addNodeHandler();
          break;
        case "release":
          this.releaseHandler();
          break;
        case "goBack":
          this.graftSaveHandler();
          this.$router.push("/iot/topology/list");
          break;
      }
    },
    startInterval() {
      this.intervalIns = window.setInterval(() => {
        this.graftSaveHandler();
      }, this.intervalStep);
    },
    graftSaveHandler(cb) {
      let params = {
        topoName: this.releaseName,
        draftTopoJson: this.buildGraphDatas(),
      };
      if (this.topoId) {
        // 修改
        params.topoId = this.topoId;
        editTopo(params)
          .then(() => {})
          .finally(() => {
            cb && cb();
          });
      } else {
        // 新增
        createTopo(params)
          .then((topoId) => {
            if (topoId) {
              this.topoId = topoId;
            }
          })
          .finally(() => {
            cb && cb();
          });
      }
    },
    releaseSaveHandler() {
      // 保存场景
      this.$refs.releaseForm.validate((v) => {
        if (v) {
          const graphDatas = this.buildGraphDatas();
          const params = {
            topoId: this.topoId,
            topoName: this.releaseForm.releaseName,
            publishTopoJson: graphDatas,
          };
          console.log("提交参数：", params);
          publishTopo(params).then(() => {
            this.releaseName = this.releaseForm.releaseName;
            this.$message.success("发布成功！");
            this.releaseDialog.show = false;
            this.topoStatus = "publish";
          });
        }
      });
    },
    buildGraphDatas() {
      const mind_data = this.jsMindIns.get_data();
      const mind_string = jsMind.util.json.json2string(mind_data);
      return mind_string;
    },
    releaseNameUpdate(name) {
      this.releaseName = name;
    },
    releaseHandler() {
      this.graftSaveHandler(() => {
        this.releaseDialog.show = true;
        this.releaseForm.releaseName = this.releaseName;
      });
    },
    releaseDialogClose() {
      this.releaseDialog.show = false;
    },
  },
};
</script>

<style lang="less" scoped>
.topology-detail-component {
  height: 100vh;
  width: 100%;
  background: #ffffff;
  position: relative;
  .graph-container {
    height: calc(100vh - 70px);
    width: 100%;
    background: #ffffff;
  }
  .right-detail-drawer {
    position: absolute;
    top: 70px;
    right: 0;
    transition: right 0.3s;
  }
  ::v-deep .js_node {
    height: 35px;
    line-height: 35px;
    display: inline-block;
    min-width: 100px;
    text-align: center;
  }
}
</style>