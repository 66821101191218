<template>
  <div class="topology-right-drawer">
    <div class="device-container">
      <div class="title-line">
        <img src="@/assets/images/topology/device_title_icon.png" alt="" />
        <span class="title-label"> 设备绑定 </span>
      </div>
      <div class="selector-container">
        <div class="selector-label">产品</div>
        <div>
          <el-select
            class="single-selector"
            v-model="product"
            filterable
            placeholder="请选择产品"
            @change="productChange"
          >
            <el-option
              v-for="item in productOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="selector-container">
        <div class="selector-label">设备</div>
        <div>
          <el-select
            class="single-selector"
            v-model="device"
            filterable
            :remote-method="remoteMethod"
            remote
            placeholder="请选择设备"
            @change="deviceChange"
          >
            <el-option
              v-for="item in deviceOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
              <span style="float: left">{{ item.label }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{
                item.value
              }}</span>
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div class="style-container">
      <div class="title-line">
        <img src="@/assets/images/topology/style_title_icon.png" alt="" />
        <span class="title-label"> 样式编辑 </span>
      </div>
      <div class="text-container">
        <div class="label-line">文本</div>
        <!--<div class="font-line">
           <el-select
            style="width: 170px"
            v-model="style.fontFamily"
            placeholder="请选择字体"
          >
            <el-option
              v-for="item in fontOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select> 
        </div>-->
        <div class="font-line">
          <el-input-number
            style="width: 140px"
            v-model="style.fontSize"
            controls-position="right"
            @change="fontSizeChange"
            :min="12"
            :max="30"
          ></el-input-number>
          <!-- <input
            class="color-selector"
            type="color"
            @change="fontColorChange"
            v-model="style.color"
          /> -->
          <el-color-picker
            class="color-selector"
            v-model="style.color"
            @change="fontColorChange"
          ></el-color-picker>
          <!-- <span class="align-span">
            <span
              :class="['single-span', item.active && 'single-span-active']"
              v-for="(item, index) in alignItems"
              :key="index"
            >
              <img :src="item.icon" alt="" />
            </span>
          </span> -->
        </div>
        <div class="font-style-line">
          <span
            :class="[
              'single-item',
              item.active && 'single-item-active',
              index > 0 && 'single-border-span',
            ]"
            v-for="(item, index) in fontStyleItems"
            :key="index"
            @click="fontStyleChange(item)"
          >
            <img :src="item.icon" alt="" />
          </span>
        </div>
      </div>
      <!-- <div class="border-container">
        <div class="label-line">边框</div>
        <div class="border-line">
          <el-input-number
            style="width: 90px"
            v-model="border.borderSize"
            controls-position="right"
            :min="1"
            :max="20"
          ></el-input-number>
          <el-select
            style="width: 90px; margin-left: 5px"
            class="single-selector"
            v-model="border.borderStyle"
          >
            <el-option
              v-for="item in borderStyleOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <input class="color-selector" type="color" v-model="border.color" />
        </div>
      </div> -->
      <div class="tag-container">
        <div class="label-line">标签</div>
        <div class="tag-line">
          <div
            class="tag-detail"
            :style="{
              background: item.color,
            }"
            v-for="(item, index) in tagItems"
            :key="index"
            @click="tagChange(item, index)"
          >
            <img
              src="@/assets/images/topology/item_active_icon.png"
              v-show="item.active"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="remark-container">
        <div class="label-line">备注</div>
        <el-input
          type="textarea"
          :rows="4"
          :autosize="false"
          placeholder="请输入内容"
          @input="remarkChange"
          v-model="nodeRemark"
        >
        </el-input>
      </div>
    </div>
  </div>
</template>

<script>
import {
  findDevicePage,
  findDevicePage1,
} from "@/api/ruge/vlink/device/device";
export default {
  name: "topologyRightDrawer",
  props: {
    currentDatas: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  watch: {
    currentDatas: {
      handler(datas) {
        console.log("xxx-datas", datas);
        const { productKey, deviceName, remark, deviceDesc } = datas.data || {};
        const parentData = datas.parent;
        this.product = productKey || parentData.data.productKey;
        this.device = deviceName;
        this.deviceDesc = deviceDesc;
        this.nodeRemark = remark;
        this.style.color = datas["foreground-color"] || "#000000";
        this.style.fontSize = datas["font-size"] || 16;
        this.setFontStyles(datas);
        this.setTagActive(datas["background-color"]);
        this.getDeviceList(null, null, true);
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      product: "",
      device: "",
      deviceDesc: "",
      style: {
        fontFamily: "",
        color: "#000000",
        fontSize: 16,
      },
      // parrentDeviceIdMap: {
      //   '锦绣一期-水表': 60,
      //   '锦绣二期-水表': 15388,
      //   '锦绣三期-水表': 15389,
      //   '锦绣一期-电表': 60,
      //   '锦绣二期-电表': 15388,
      //   '锦绣三期-电表': 15389,
      //   '锦绣三期-冷量表': 15389,
      // },
      productOptions: [
        {
          value: "GrYXHrZZaDi-60",
          label: "锦绣一期-水表",
        },
        {
          value: "KrZYHrbYYja-15388",
          label: "锦绣二期-水表",
        },
        {
          value: "KrZYHrbYYja-15389",
          label: "锦绣三期-水表",
        },
        {
          value: "jrWYKrXaby3-60",
          label: "锦绣一期-电表",
        },
        {
          value: "irZairXXaj8-15388",
          label: "锦绣二期-电表",
        },
        {
          value: "irZairXXaj8-15389",
          label: "锦绣三期-电表",
        },
        {
          value: "GrYbjrZbXHK-15389",
          label: "锦绣三期-冷量表",
        },
        {
          value: "HrZXirXWDCa-15389",
          label: "锦绣三期-变配电低压柜",
        },
        {
          value: "irYbKrZYDDH-15389",
          label: "锦绣三期-变配电高压柜",
        },
        {
          value: "GrZaGrbWYmk-15389",
          label: "贝龙-总冷表",
        },
      ],
      deviceOptions: [],
      resourceOptions: [],
      fontOptions: [],
      alignItems: [
        {
          active: true,
          value: "left",
          icon: require("@/assets/images/topology/align_left_icon.png"),
        },
        {
          active: false,
          value: "center",
          icon: require("@/assets/images/topology/align_center_icon.png"),
        },
        {
          active: false,
          value: "right",
          icon: require("@/assets/images/topology/align_right_icon.png"),
        },
      ],
      fontStyleItems: [
        {
          icon: require("@/assets/images/topology/font_bold_icon.png"),
          active: false,
          key: "fontWeight",
        },
        {
          icon: require("@/assets/images/topology/font_italic_icon.png"),
          active: false,
          key: "fontItalic",
        },
        // {
        //   icon: require("@/assets/images/topology/font_underline_icon.png"),
        //   active: false,
        //   key: "fontUnderline",
        // },
        // {
        //   icon: require("@/assets/images/topology/font_through_icon.png"),
        //   active: false,
        //   key: "fontThrough",
        // },
      ],
      border: {
        borderSize: 12,
        style: "solid",
        color: "#000000",
      },
      borderStyleOptions: [
        {
          value: "solid",
          label: "实线",
        },
        {
          value: "dotted",
          label: "虚线",
        },
      ],
      tagItems: [
        {
          color: "#F16E36",
          active: true,
        },
        {
          color: "#FFB629",
          active: false,
        },
        {
          color: "#61EB31",
          active: false,
        },
      ],
      nodeRemark: "",
    };
  },
  methods: {
    setFontStyles(datas) {
      this.fontStyleItems.forEach((item) => {
        if (item.key === "fontWeight") {
          item.active = datas["font-weight"] === "bold";
        }
        if (item.key === "fontItalic") {
          item.active = datas["font-style"] === "italic";
        }
      });
    },
    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        this.getDeviceList(query);
      } else {
        this.deviceOptions = this.resourceOptions;
      }
    },
    getDeviceList(deviceDesc, resetDevice, initFlag) {
      console.log("deviceDesc", deviceDesc);
      // if (!initFlag) {
      //   this.device = "";
      //   this.$emit("propsChange", {
      //     key: "device",
      //     value: ["", ""],
      //   });
      //   this.deviceOptions = [];
      //   return;
      // }
      findDevicePage1({
        // productKey: this.product.split("-")[0],
        // parentDeviceId: this.product.split("-")[1],
        // parentDeviceName: this.product.split("-")[1],
        // searchPhrase: deviceDesc || "",
        // productKeys: 'GrYbjrZbXHK-15389, irYbKrZYDDH-15389',
        // productKeys: Array.from(
        //   new Set(this.productOptions.map((item) => item.value.split("-")[0]))
        // ).join(","),
        parentDeviceIds: this.product
          ? this.product
          : this.productOptions.map((item) => item.value).join(","),
        searchPhrase: deviceDesc,
        current: 1,
        rowCount: 50,
      }).then((res) => {
        if (resetDevice) {
          this.device = "";
          // this.$emit("propsChange", {
          //   key: "device",
          //   value: ["", ""],
          // });
        }
        const resultList = res.map((item) => {
          return {
            value: item.deviceName,
            label: item.description,
            productKey: item.productKey,
            parentDeviceId: item.parentDeviceId,
          };
        });
        this.deviceOptions = resultList;
        //
        if (
          this.deviceDesc &&
          this.device &&
          !this.checkExsit(this.device, this.deviceOptions)
        ) {
          this.deviceOptions.push({
            value: this.device,
            label: this.deviceDesc,
          });
          this.deviceDesc = "";
        }
        if (!this.resourceOptions.length) {
          this.resourceOptions = resultList;
        }
      });
    },
    checkExsit(device, options) {
      for (let i = 0; i < options.length; i++) {
        if (options[i].value === device) {
          return true;
        }
      }
      return false;
    },
    fontStyleChange(item) {
      item.active = !item.active;
      switch (item.key) {
        case "fontWeight":
          this.$emit("propsChange", {
            key: "fontWeight",
            value: item.active ? "bold" : "normal",
          });
          break;
        case "fontItalic":
          this.$emit("propsChange", {
            key: "fontItalic",
            value: item.active ? "italic" : "normal",
          });
          break;
      }
    },
    remarkChange(value) {
      this.$emit("propsChange", {
        key: "remark",
        value,
      });
    },
    tagChange({ color, active }, activeIndex) {
      if (active) {
        this.setTagActive();
        this.$emit("propsChange", {
          key: "backgroundColor",
          value: "#ffffff",
        });
        return;
      }
      this.setTagActive(color);
      this.$emit("propsChange", {
        key: "backgroundColor",
        value: color,
      });
    },
    setTagActive(color) {
      this.tagItems.forEach((item) => {
        item.active = item.color === color;
      });
    },
    fontSizeChange() {
      this.$emit("propsChange", {
        key: "fontSize",
        value: this.style.fontSize,
      });
    },
    fontColorChange() {
      console.log("color", this.style.color);
      this.$emit("propsChange", {
        key: "fontColor",
        value: this.style.color,
      });
    },
    productChange(product) {
      this.resourceOptions = [];
      this.$forceUpdate();
      this.getDeviceList("", true);
      this.$emit("propsChange", {
        key: "product",
        value: product,
      });
    },
    deviceChange(device) {
      console.log("device", device);
      if (!device) {
        this.$emit("propsChange", {
          key: "device",
          value: ["", ""],
        });
        return;
      }
      this.setProductByDevice(device);
      const resultDesc = this.deviceOptions.filter((item) => {
        return item.value === device;
      })[0].label;
      console.log("resultDesc", this.deviceOptions);
      this.$emit("propsChange", {
        key: "device",
        value: [device, resultDesc],
      });
    },
    setProductByDevice(device) {
      if (!device) return;
      const deviceSource = this.deviceOptions.filter(
        (item) => item.value === device
      )[0];
      console.log("deviceSource", deviceSource);
      const { productKey, parentDeviceId } = deviceSource || {};
      if (productKey && parentDeviceId) {
        this.product = `${productKey}-${parentDeviceId}`;
        console.log("this.product", this.product);
        this.$emit("propsChange", {
          key: "product",
          value: `${productKey}-${parentDeviceId}`,
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.topology-right-drawer {
  z-index: 2;
  width: 280px;
  height: calc(100vh - 70px);
  border-left: 1px solid #e3e8ee;
  background: #f1f6fd;
  .single-selector {
    width: 100%;
  }
  .title-line {
    border-bottom: 1px solid #e3e8ee;
    display: flex;
    align-items: center;
    padding-bottom: 12px;
    .title-label {
      font-size: 18px;
      font-weight: 600;
      color: #152c5b;
      line-height: 20px;
      margin-left: 3px;
    }
  }
  .device-container {
    border-bottom: 1px solid #e3e8ee;
    padding: 20px 20px 30px 20px;

    .selector-container {
      .selector-label {
        margin: 15px 0 8px;
        font-size: 16px;
        font-weight: 600;
        color: #152c5b;
        line-height: 16px;
      }
    }
  }
  .style-container {
    padding: 20px 20px 30px 20px;
    .text-container {
      border-bottom: 1px solid #e3e8ee;
      padding-bottom: 25px;
      .font-line {
        display: flex;
        align-items: center;
        .color-selector {
          border: none;
          height: 40px;
          width: 100px;
          padding: 0;
          margin-left: 15px;
          cursor: pointer;
          display: flex;
          align-items: center;
          ::v-deep .el-color-picker__trigger {
            width: 100px;
          }
        }
      }
      .align-line {
        display: flex;
        align-items: center;
        margin-top: 15px;
        .align-span {
          display: inline-block;
          width: 140px;
          height: 34px;
          background: #ffffff;
          border-radius: 4px;
          padding: 2px 8px;
          margin-left: 10px;
          display: flex;
          justify-content: space-between;
          .single-span {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 30px;
            height: 30px;
            border-radius: 4px;
            cursor: pointer;
          }
          .single-span-active {
            background: #e8eefb;
          }
        }
      }
      .font-style-line {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 15px;
        height: 34px;
        width: 240px;
        background: #ffffff;
        border-radius: 4px;
        padding: 2px 50px;
        .single-item {
          padding: 2px 10px;
          width: 30px;
          height: 30px;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
        .single-item-active {
          background: #e8eefb;
        }
        .single-border-span {
          position: relative;
          &::before {
            content: "";
            width: 1px;
            height: 9px;
            border: 1px solid #eff1f3;
            position: absolute;
            left: -37px;
            top: 10.5px;
          }
        }
      }
    }
    .border-container {
      border-bottom: 1px solid #e3e8ee;
      padding-bottom: 25px;
      .border-line {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        .color-selector {
          border: none;
          height: 40px;
          width: 60px;
          padding: 0;
          margin-left: 5px;
          cursor: pointer;
        }
      }
    }
    .tag-container {
      border-bottom: 1px solid #e3e8ee;
      padding-bottom: 25px;
      .tag-line {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .tag-detail {
          display: inline-block;
          width: 70px;
          height: 34px;
          border-radius: 4px;
          position: relative;
          cursor: pointer;
          img {
            position: absolute;
            top: 0;
            right: 0;
          }
        }
      }
    }
    .remaork-container {
    }
  }
  .label-line {
    margin: 15px 0 8px;
    font-size: 16px;
    font-weight: 600;
    color: #152c5b;
    line-height: 16px;
  }
}
</style>